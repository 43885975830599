import { LOCAL } from '@/constants';
import { uiHelper } from '@/utils';

export default function constantsProjectRoles(value, nameArr = false) {
  let header = 'project_roles';
  let description;
  let newArray = [];
  if (header && !['', undefined, null].includes(value)) {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        value.forEach((x) => {
          const constant = getConstants(header, x, nameArr);
          if (constant) newArray.push(constant);
        });
        description = newArray;
      } else {
        description = '-';
      }
    } else {
      description = getConstants(header, value);
    }
  } else {
    description = '-';
  }
  return description;
}
function getConstants(header, value, nameArr) {
  let constantsData = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));
  const result = constantsData[header].find((x) => x.id == value)

  if (nameArr) {
    return result ? result.name : ''
  }

  let data = {
    id: result.id,
    name: result.name,
    isDeleted: result.is_deleted
  };
  return data;
}
