<template>
  <div>
    <h1 class="text-uppercase mb-5">Select {{ title }}</h1>
    <div v-if="this.list.length || 0">
      <div class="mb-2">({{ me.role == 'talent' ? (selectedId ? 1 : 0) : selectedId.length }})</div>
      <v-row no-gutters justify="center">
        <v-col
          class="userContainer mb-5"
          v-for="(user, index) in list"
          :key="index"
          cols="12"
        >
          <!-- <div class="py-5 px-10 align-self-center">
            <v-avatar size="130">
              <v-img :src="item.logo_image.media_path"/>
            </v-avatar>
          </div> -->
          <v-row no-gutters>
            <!-- if its talent, then the modal should display agency list -->
            <v-col
              class="px-5 d-flex my-2"
              cols="12"
              md="12"
              v-if="me.role == 'talent'"
            >
              <div class="align-self-center">
                <div class="titleFont">
                  {{ getAgencyCategoryDesc(user.agency_category) }}
                </div>
                <div>
                  {{ user.agency }}
                </div>
              </div>
              <div class="ml-auto">
                <v-checkbox
                  on-icon="mdi-check-circle-outline"
                  off-icon="mdi-checkbox-blank-circle-outline"
                  :value="user.agency_id"
                  v-model="selectedId"
                  color="primary"
                  :multiple="me.role == 'agency'"
                ></v-checkbox>
              </div>
            </v-col>
            <v-col class="px-5 d-flex my-2" cols="12" md="12" v-else>
              <div class="d-flex align-self-center">
                <div class="pr-3">
                  <v-avatar>
                    <img :src="getHeadshotFile(user.headshot_file)" crossorigin="anonymouse">
                  </v-avatar>
                </div>
                <div class="">
                  <div>
                    <div class="titleFont">
                      {{ user.display_name }}
                    </div>
                    <div>
                      {{ user.fname }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="ml-auto">
                <v-checkbox
                  on-icon="mdi-check-circle-outline"
                  off-icon="mdi-checkbox-blank-circle-outline"
                  :value="user.user_id"
                  v-model="selectedId"
                  color="primary"
                  :multiple="!isTalent"
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" class="mt-12 pt-10 text-center">
          <v-btn class="btn1" :disabled="!selectedId" @click="apply">
            Apply
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-else class="grey--text">
      {{
        me.role == 'talent'
          ? 'You are not linked to any agency'
          : 'No linked talent'
      }}
      <div v-if="me.role == 'talent'" class="mt-12 pt-10 text-center">
        <v-btn class="btn1" @click="apply"> Apply </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LinkedAccountListModal',
    props: {
      list: {
        type: Array,
        default: () => []
      },
      title: {
        type: String,
        default: ''
      },
      me: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        selectedId: this.me.role == 'talent' ? null : []
      };
    },
    computed: {
      constantsAgencyCategory() {
        return this.$store.getters['constants/agencyCategory'];
      },
      defaultProfilePhoto() {
        return this?.$store?.getters['constants/avatarImageUrl'];
      },
      isTalent() {
        return this.me.role == 'talent';
      }
    },
    methods: {
      getAgencyCategoryDesc(val) {
        return this.constantsAgencyCategory.find((x) => x.value === val)
          .description;
      },
      getHeadshotFile(val) {
        if (!val) {
          return this.defaultProfilePhoto;
        }
        return val?.media_path;
      },
      apply() {
        let payload = {
          talentId: this.isTalent ? this.me.id : this.selectedId,
          agencyId: this.isTalent ? this.selectedId : this.me.id
        };

        // If payload.talentId is not array, then turn it into array
        if (!Array.isArray(payload.talentId)) payload.talentId = [payload.talentId];

        if (!this.isTalent) {
          // Remove element in payload.talentId if user_id doesn't exist in props.list
          const listSet = new Set(this.list.map(i => i.user_id));
          payload.talentId = payload.talentId.filter(i => listSet.has(i));
        }

        this.$emit('apply', payload);
      }
    }
  };
</script>

<style scoped lang="scss">
  .userContainer {
    background-color: #404040;
    border-radius: 5px;
  }
  .titleFont {
    font-size: 1rem;
  }
</style>
