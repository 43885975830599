<template>
  <div class="container wrapper">
    <div v-if="jobObj.producer_uid == me.id" class="d-flex justify-end">
      <v-menu dark left z-index="1" offset-y>
        <template v-slot:activator="{ on }">
          <v-icon dark v-on="on">mdi-dots-horizontal</v-icon>
        </template>
        <v-list>
          <v-list-item
            @click="
              $router.push({
                name: 'create_job',
                query: {
                  job_id: jobObj.job_id
                }
              })
            "
          >
            <v-list-item-title class="px-8"> Edit Post </v-list-item-title>
          </v-list-item>
          <hr class="divider" />
          <v-list-item @click="$openModal('deleteConfirmationModal')">
            <v-list-item-title class="px-8">Remove Post</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-row
      no-gutters
      class="w-100 pa-4 pa-lg-10 listBg mt-md-10"
      :class="jobObj.job_cover_file ? 'showJob' : 'mt-lg-15'"
    >
      <v-col cols="12">
        <v-row no-gutters>
          <v-col
            lg="6"
            md="6"
            cols="12"
            v-if="jobObj.job_cover_file"
            class="text-center"
          >
            <img
              :src="jobObj.job_cover_file.media_path"
              alt=""
              class="jobImage pr-lg-6"
              crossorigin="anonymous"
            />
          </v-col>
          <v-col lg="6" md="6">
            <router-link
              class="text-decoration-none white--text d-flex align-center pt-3"
              :to="{
                name: routeName.PROFILE,
                query: {
                  id: jobObj.producer_uid //temp putting job id, suppose to be producer_id
                }
              }"
              target="_blank"
            >
              <!-- verification-status -->
              <span class="verification-status">
                <v-avatar size="50">
                  <img :src="getPhoto(jobObj)" alt="" crossorigin="anonymous" />
                </v-avatar>
                <v-icon
                  v-if="jobObj.producerIsVerified"
                  color="#FF2FF2"
                  class="icon"
                >
                  mdi-checkbox-marked-circle
                </v-icon>
              </span>
              <span class="pl-3">{{ jobObj.producer }}</span>
            </router-link>
            <div class="pt-3 jobTitle">{{ jobObj.title }}</div>
            <div class="pt-3">
              <span class="deep-blue--text">{{
                jobObj.project_type_id.name
              }}</span>
              <span class="pl-3" v-if="jobObj.is_wfh">Work from home</span>
              <span class="pl-3" v-if="jobObj.is_paid">Paid Job</span>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-6">
          <div>
            <div class="pink2--text">Synopsis</div>
            <div>
              {{ jobObj.synopsis }}
            </div>
          </div>
        </v-row>
        <v-row no-gutters class="pt-6">
          <v-col lg="6" md="6" cols="12" v-if="jobObj.casting_roles.length">
            <div class="pink2--text">Character / Role</div>
            <div v-for="(role, index) in jobObj.casting_roles" :key="index">
              <div>
                <span class="font-weight-bold">{{ role.character }}</span>
                <span class="text-capitalize">{{
                  role.gender ? `, ${role.gender} ` : ' '
                }}</span>
                (On-screen Age {{ role.age_range }})
              </div>
            </div>
          </v-col>
          <v-col
            lg="3"
            md="3"
            cols="12"
            class="py-lg-0 py-4"
            :class="!jobObj.casting_roles.length ? '' : 'px-lg-6'"
          >
            <div class="pink2--text">Job Location</div>
            <div
              v-for="(jobCountryId, x) in jobObj.job_country_id"
              :key="x"
              class="d-flex align-center pt-3"
            >
              <img
                :src="jobCountryId.imgCircle"
                alt=""
                width="30px"
                class="mr-5"
                crossorigin="anonymous"
              />
              <div>{{ jobCountryId.name }}</div>
            </div>
          </v-col>
          <v-col lg="3" md="3" cols="12">
            <div class="pink2--text">Preferred Country of Applicant</div>
            <div
              v-for="(appCountryId, x) in jobObj.applicant_country_id"
              :key="x"
              class="d-flex align-center pt-3"
            >
              <img
                :src="appCountryId.imgCircle"
                alt=""
                width="30px"
                class="mr-5"
                crossorigin="anonymous"
              />
              <div>{{ appCountryId.name }}</div>
            </div>
          </v-col>
        </v-row>
        <hr class="divider my-5" />
        <v-row no-gutters :class="!$isMobile() ? 'pt-6' : ''">
          <v-col cols="12">
            <v-row no-gutters>
              <v-col
                cols="6"
                lg="3"
                md="3"
                class="pr-3"
                :class="$isMobile() ? 'align-self-end' : ''"
              >
                <div class="pink2--text">Video Submission Date</div>
                <div>{{ defaultDate(jobObj.video_due) }}</div>
              </v-col>
              <v-col
                cols="6"
                lg="3"
                md="3"
                :class="$isMobile() ? 'align-self-end' : ''"
              >
                <div class="pink2--text">Closing Date</div>
                <div>{{ defaultDate(jobObj.close_date) }}</div>
              </v-col>
              <v-col cols="12" lg="3" md="3" :class="$isMobile() ? 'py-3' : ''">
                <div class="pink2--text">Posting Date</div>
                <div>{{ defaultDate(jobObj.live_date) }}</div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div class="mt-10">
      <v-row no-gutters>Production Details </v-row>
      <v-row no-gutters class="w-100 pa-4 pa-lg-10 listBg mt-5">
        <v-col cols="6" md="3" lg="3" class="mb-4">
          <div class="pb-2 pink2--text">Start</div>
          <div>{{ defaultDate(jobObj.production_period_from) }}</div>
        </v-col>
        <v-col cols="6" md="3" lg="3">
          <div class="pb-2 pink2--text">End</div>
          <div>{{ defaultDate(jobObj.production_period_to) }}</div>
        </v-col>
        <v-col cols="12" md="3">
          <div class="d-flex align-item-center" v-if="jobObj.shooting_days">
            <v-icon color="white" class="mr-4 pb-2">mdi-calendar-blank</v-icon>
            <div>{{ jobObj.shooting_days }} Shoot days</div>
          </div>
          <div class="d-flex align-item-center" v-if="jobObj.hours_per_day">
            <v-icon color="white" class="mr-4 pb-2"
              >mdi-calendar-clock-outline</v-icon
            >
            <div>{{ jobObj.hours_per_day }} hours per day</div>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="mt-10" v-if="jobObj.casting_roles.length">
      <v-row no-gutters>Character Details </v-row>
      <v-row
        no-gutters
        class="w-100 pa-4 pa-lg-10 listBg mt-5"
        :id="`role-${castingRole.id}`"
        v-for="(castingRole, index) in jobObj.casting_roles"
        :key="index"
      >
        <v-col cols="12" md="4" class="pr-3">
          <div class="pb-2 pink2--text">
            <span class="font-weight-bold">{{ castingRole.character }}</span>
            <span class="text-capitalize">{{
              castingRole.gender ? `, ${castingRole.gender} ` : ' '
            }}</span>
            (On-screen Age {{ castingRole.age_range }})
          </div>
          <div class="pb-2">
            {{ castingRole.project_roles }}
          </div>
          <div class="pb-2">
            {{ castingRole.character_write_up }}
          </div>
          <div>{{ castingRole.skills }}</div>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="mb-5"
          :class="!$isMobile() ? 'pl-2' : ''"
        >
          <div class="d-flex align-item-center">
            <v-icon color="white" class="mr-4 pb-2">mdi-cash </v-icon>
            <div>
              {{
                castingRole.fees == 0
                  ? 'Fees to be negotiated'
                  : castingRole.currency + ' ' + castingRole.fees + ' per day'
              }}
            </div>
          </div>
          <div class="d-flex align-item-center">
            <v-icon color="white" class="mr-4 pb-2"
              >mdi-calendar-clock-outline</v-icon
            >
            <div>{{ castingRole.opening_number }} Opening</div>
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <template v-if="isTalentOrAgency">
            <div
              v-if="
                castingRole.invitation_id &&
                ['pending'].includes(castingRole.invitation_status) &&
                !castingRole.is_applied
              "
              class="text-center"
            >
              <div class="mb-4 mobileButtonContainer">
                <v-btn
                  class="btn1"
                  @click="
                    respondToInvitation(
                      castingRole.invitation_id,
                      'accepted',
                      castingRole.id
                    )
                  "
                >
                  {{ $t('label.imInterested') }}
                </v-btn>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon dark v-on="on" class="mobileTooltipPosition">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{
                    $t('message.invitationInformationIconMessage')
                  }}</span>
                </v-tooltip>
              </div>

              <div class="text-center">
                <a
                  class="underlineBtn"
                  @click="
                    respondToInvitation(castingRole.invitation_id, 'declined')
                  "
                >
                  Drop Invitation
                </a>
              </div>
            </div>
            <div class="text-center" v-else>
              <v-btn
                :id="`apply-btn_${castingRole.id}`"
                class="btn1"
                @click="
                  () => {
                    checkBeforeApply(castingRole.id);
                  }
                "
                :disabled="
                  jobObj.is_expired ||
                  (me.role == 'talent' && castingRole.is_applied)
                "
              >
                {{
                  me.role == 'talent' && castingRole.is_applied
                    ? 'Applied'
                    : 'Apply'
                }}
              </v-btn>
            </div>
          </template>

          <div
            class="mt-3"
            :class="isTalentOrAgency ? 'text-center' : 'text-right'"
          >
            <a
              v-if="castingRole.casting_script_file"
              @click="downloadScriptFile(castingRole.casting_script_file)"
              target="_blank"
              class="underlineBtn white--text"
            >
              Download Casting Script
            </a>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="mt-10" v-if="jobObj.project_type_id.datasetId == 4">
      <v-row no-gutters class="mb-2">Customise Details</v-row>
      <v-row no-gutters class="listBg w-100 pa-4 pa-lg-10">
        <v-col cols="12" class="mb-5">
          <div
            v-for="(field, idx) in jobObj.create_own_fields"
            :key="idx"
            class="deep-blue--text"
          >
            {{ field.name }} : {{ field.value }}
          </div>
        </v-col>
        <v-col cols="12">
          <div class="grey--text">
            Fees : {{ jobObj.currency }} {{ jobObj.fees.toFixed(2) }}
          </div>
        </v-col>
      </v-row>
    </div>
    <div
      class="text-center mt-12 pt-5"
      v-if="jobObj.project_type_id.datasetId == 4"
    >
      <template v-if="isTalentOrAgency">
        <div
          v-if="
            !jobObj.is_applied &&
            jobObj.invitation_id &&
            ['pending'].includes(jobObj.invitation_status)
          "
          class="text-center"
        >
          <!-- buttons for invitation -->
          <div class="mb-4 mobileButtonContainer">
            <v-btn
              class="btn1"
              @click="respondToInvitation(jobObj.invitation_id, 'accepted')"
            >
              {{ $t('label.imInterested') }}
            </v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon dark v-on="on" class="mobileTooltipPosition bottom">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>{{ $t('message.invitationInformationIconMessage') }}</span>
            </v-tooltip>
          </div>

          <div class="text-center">
            <a
              class="underlineBtn"
              @click="
                respondToInvitation(castingRole.invitation_id, 'declined')
              "
            >
              Drop Invitation
            </a>
          </div>
        </div>
        <div v-else>
          <v-btn
            id="apply-btn"
            class="btn1"
            @click="
              () => {
                checkBeforeApply();
              }
            "
            :disabled="me.role == 'talent' && jobObj.is_applied"
          >
            {{ jobObj.is_applied ? 'Applied' : 'Apply' }}
          </v-btn>
        </div>
      </template>

      <div class="my-6">
        <a
          v-if="jobObj.casting_script_file"
          @click="downloadScriptFile(jobObj.casting_script_file)"
          target="_blank"
          class="underlineBtn white--text"
        >
          Download Casting Script
        </a>
      </div>
    </div>
    <div class="text-center mt-12 pt-5">
      <a
        class="mx-2 underlineBtn text-decoration-none lg-font"
        @click="redirectBackward"
      >
        <v-icon dark color="primary" large>
          mdi-arrow-left-bold-circle-outline
        </v-icon>
        Back
      </a>
    </div>
    <ModalBase width="500" id="deleteConfirmationModal">
      <div>
        <div class="text-center text-uppercase my-5 font-weight-bold lg-font">
          Confirm to Remove This Post?
        </div>
        <div class="text-center pt-8">
          <v-btn class="btn1 mb-4" @click="removeJob"> Remove </v-btn>
          <div class="underlineBtn" @click="$closeModal()">No</div>
        </div>
      </div>
    </ModalBase>
    <ModalBase width="800" id="unionNoModal">
      <div>
        <div class="text-center text-uppercase my-5 font-weight-bold lg-font">
          Please enter the Union Number
        </div>
        <div class="text-center pt-8">
          <v-form
            ref="unionForm"
            v-model="form.unionNo.valid"
            lazy-validation
            class="businessInfoForm w-100"
          >
            <AppFormField
              v-model="form.unionNo.value"
              name="form_unionNo"
              label="Union Number"
              type="text"
              :rules="form.unionNo.rules"
              itemColor="background"
              color="surface"
              outlined
              dark
            />
          </v-form>
        </div>
        <div class="text-center pt-8">
          <v-btn class="btn1 mb-4" @click="submitUnionNumber"> Submit </v-btn>
          <div class="underlineBtn" @click="$closeModal()">Cancel</div>
        </div>
      </div>
    </ModalBase>
    <ModalBase id="linkedAccListModal">
      <LinkedAccountListModal
        :list="list"
        :title="me.role == 'agency' ? 'talent' : 'agency'"
        :me="me"
        :roleId="roleId"
        @apply="apply"
      />
    </ModalBase>
  </div>
</template>

<script>
  import Moment from 'moment';
  import { DEFAULT_DATE_FORMAT } from '@/constants/constants';
  import { formValidationHelper } from '@/utils';
  import {
    JOB_ORGANIZER_INITIAL_GET_CASTING_JOB_BY_ID_STATE,
    JOB_ORGANIZER_GET_CASTING_JOB_BY_ID,
    JOB_ORGANIZER_INITIAL_GET_TALENT_LIST_STATE,
    JOB_ORGANIZER_GET_TALENT_LIST,
    JOB_ORGANIZER_INITIAL_APPLY_ROLE_STATE,
    JOB_ORGANIZER_APPLY_ROLE,
    JOB_ORGANIZER_CHECK_REAPPLY,
    JOB_ORGANIZER_INITIAL_CHECK_REAPPLY_STATE,
    JOB_ORGANIZER_UPDATE_INVITATION_STATUS,
    JOB_ORGANIZER_INITIAL_UPDATE_INVITATION_STATUS_STATE
  } from '@/store/job-organizer.module';
  import {
    PROFILE_INITIAL_GET_LINKED_AGENCY_STATE,
    PROFILE_GET_LINKED_AGENCY
  } from '@/store/profile.module';
  import constantsProjectTypes from '@/filters/constants-project-types.filter';
  import constantsProjectRoles from '@/filters/constants-project-roles.filter';
  import constantsCountry from '@/filters/constants-country.filter';
  import ModalBase from '@/components/base/ModalBase';
  import LinkedAccountListModal from '@/components/job/LinkedAccountListModal';
  import { JOB_ORGANIZER } from '@/api';
  import { ROUTE_NAME } from '@/constants';

  export default {
    name: 'JobDetails',
    components: {
      ModalBase,
      LinkedAccountListModal
    },
    props: {
      id: {
        type: [Number, String],
        default: null
      }
    },
    data() {
      return {
        jobObj: {
          project_type_id: {
            name: null,
            datasetId: null
          },
          casting_roles: []
        },
        form: {
          unionNo: {
            value: '',
            store: null,
            valid: false,
            rules: [formValidationHelper.unionNo()]
          }
        },
        list: [],
        roleId: null,
        routeName: ROUTE_NAME
      };
    },
    computed: {
      me() {
        return this.$store.state.auth.me.data;
      },
      defaultProfilePhoto() {
        return this?.$store?.getters['constants/avatarImageUrl'];
      },
      isTalentOrAgency() {
        return ['agency', 'talent'].includes(this.me.role);
      },
      getJobByIdComplete() {
        return this.$store.state.jobOrganizer.getCastingJobById.complete;
      },
      getTalentListComplete() {
        return this.$store.state.jobOrganizer.getTalentList.complete;
      },
      linkedAgencyComplete() {
        return this.$store.state.profile.linkedAgency.complete;
      },
      applyRoleComplete() {
        return this.$store.state.jobOrganizer.applyRole.complete;
      },
      checkReapplyComplete() {
        return this.$store.state.jobOrganizer.checkReapply.complete;
      },
      updateInvitationStatusComplete() {
        return this.$store.state.jobOrganizer.updateInvitationStatus.complete;
      }
    },
    watch: {
      getJobByIdComplete() {
        let response = this.$store.state.jobOrganizer.getCastingJobById;
        if (response.complete) {
          this.importJobObj(response);
        }
      },
      getTalentListComplete() {
        let response = this.$store.state.jobOrganizer.getTalentList;
        if (response.complete) {
          this.importTalentList(response);
        }
      },
      linkedAgencyComplete() {
        let response = this.$store.state.profile.linkedAgency;
        if (response.complete) {
          this.importLinkedAgency(response);
        }
      },
      applyRoleComplete() {
        let response = this.$store.state.jobOrganizer.applyRole;
        if (response.complete) {
          this.roleId = null;
          this.applyRoleResponse(response);
        }
      },
      checkReapplyComplete() {
        let response = this.$store.state.jobOrganizer.checkReapply;
        if (response.complete) {
          this.checkReapplyCompleteAction(response);
        }
      },
      updateInvitationStatusComplete() {
        let response = this.$store.state.jobOrganizer.updateInvitationStatus;
        if (response.complete) {
          this.updateInvitationCompleteAction(response);
        }
      }
    },
    methods: {
      defaultDate(date) {
        return Moment(date).format(DEFAULT_DATE_FORMAT);
      },
      getPhoto(job) {
        return (
          job?.producer_company_logo?.mediaPath ||
          job?.producer_company_logo?.media_path ||
          this.defaultProfilePhoto
        );
      },
      importJobObj(response) {
        const getProjectRoles = (projectRoleId) => {
          if (!projectRoleId) return null;
          let result = constantsProjectRoles(projectRoleId, true);

          if (typeof result == 'object' && Array.isArray(result)) {
            return result.join(', ');
          }
          return result;
        };

        this.jobObj = {
          ...response.data,
          project_type_id: constantsProjectTypes(response.data.project_type_id),
          job_country_id: constantsCountry(response.data.job_country_id),
          applicant_country_id: constantsCountry(
            response.data.applicant_country_id
          ),
          producerIsVerified: response.data.producer_is_verified,
          project_roles: getProjectRoles(response.data.project_role_id)
        };
        if (response.data.casting_roles) {
          this.jobObj.casting_roles = response.data.casting_roles.map((c) => {
            return {
              ...c,
              project_roles: getProjectRoles(c.project_role_id)
            };
          });
        }
        this.getJobByIdCompleteAction();
      },
      importTalentList(response) {
        if (response.code == 200) {
          this.list = response.data.talents;
          this.showModal(response.data.roleId);
        }
        this.getTalentListCompleteAction();
      },
      applyRoleResponse(response) {
        if (response.code == 200) {
          this.$openModal(
            'messageModal',
            'Role successfully applied.',
            {
              title: 'Ok',
              action: () =>
                this.$router.push(
                  `/application_management?job_id=${this.jobObj.job_id}`
                )
            },
            { show: false, customText: '' }
          );
        } else {
          this.openAppDialogInfo(
            'error',
            response.message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.applyRoleCompleteAction();
      },
      getJobByIdCompleteAction() {
        this.initialGetJobByIdState();
      },
      initialGetJobByIdState() {
        this.$store.dispatch(JOB_ORGANIZER_INITIAL_GET_CASTING_JOB_BY_ID_STATE);
      },
      getTalentListCompleteAction() {
        this.initialGetTalentListState();
      },
      initialGetTalentListState() {
        this.$store.dispatch(JOB_ORGANIZER_INITIAL_GET_TALENT_LIST_STATE);
      },
      applyRoleCompleteAction() {
        this.initialApplyRoleState();
      },
      initialApplyRoleState() {
        this.$store.dispatch(JOB_ORGANIZER_INITIAL_APPLY_ROLE_STATE);
      },
      checkReapplyCompleteAction(response) {
        if (response.code == 200) {
          if (response.data?.isExistingApplication)
            this.$openModal(
              'messageModal',
              'You had withdrawn application for this role previously, are you sure to re-apply?',
              {
                title: 'Yes',
                action: () => {
                  this.$store.dispatch(JOB_ORGANIZER_APPLY_ROLE, {
                    data: response.data?.extra
                  });
                  this.$closeModal();
                }
              },
              { show: true, customText: '' }
            );
          else
            this.$store.dispatch(JOB_ORGANIZER_APPLY_ROLE, {
              data: response.data?.extra
            });
        } else {
          this.openAppDialogInfo(
            'error',
            response.message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$store.dispatch(JOB_ORGANIZER_INITIAL_CHECK_REAPPLY_STATE);
      },
      importLinkedAgency(response) {
        if (response.code === 200) {
          this.list = response.data.linkage;
        }

        this.linkedAgencyCompleteAction();
      },

      linkedAgencyCompleteAction() {
        this.initialLinkedAgencyState();
      },
      initialLinkedAgencyState() {
        this.$store.dispatch(PROFILE_INITIAL_GET_LINKED_AGENCY_STATE);
      },
      async getTalentList(roleId) {
        await this.$store.dispatch(JOB_ORGANIZER_GET_TALENT_LIST, {
          data: {
            casting_job_id: this.id,
            casting_role_id: roleId
          }
        });
      },
      loadData() {
        this.$store.dispatch(JOB_ORGANIZER_GET_CASTING_JOB_BY_ID, {
          id: this.id
        });

        if (this.$route.query?.union_no) {
          this.form.unionNo.value = this.$route.query.union_no;
        }
      },
      getLinkedAgency() {
        this.$store.dispatch(PROFILE_GET_LINKED_AGENCY);
      },
      async removeJob() {
        this.$startLoading();
        try {
          await JOB_ORGANIZER.removeJob(this.id);
          this.$openModal('messageModal', 'Casting job deleted successfully');
          this.$router.push('/');
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
      async downloadScriptFile(file) {
        this.$startLoading();
        try {
          const response = await fetch(file.media_path);
          const blob = await response.blob();
          const newBlob = new Blob([blob]);

          const objUrl = window.URL.createObjectURL(newBlob);
          const link = document.createElement('a');
          link.href = objUrl;
          link.download = file.name;
          link.click();
          // For Firefox it is necessary to delay revoking the ObjectURL.
          setTimeout(() => {
            window.URL.revokeObjectURL(objUrl);
          }, 250);
        } catch (e) {
          console.error(e);
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
      showModal(roleId) {
        this.roleId = roleId;
        this.$openModal('linkedAccListModal');
      },
      apply(idsObj) {
        let data = {
          ...idsObj,
          jobId: this.jobObj.job_id,
          roleId: this.roleId
        };
        if (this.jobObj?.union_no_required == 1) {
          data.unionNo = Number(this.form.unionNo.value);
        }
        // this.$store.dispatch(JOB_ORGANIZER_APPLY_ROLE, { data });
        this.$store.dispatch(JOB_ORGANIZER_CHECK_REAPPLY, { data });
      },
      async updateInvitationCompleteAction(response) {
        if (response.code == 200) {
          await this.loadData();

          this.openAppDialogInfo(
            'success',
            'Invitation updated successfully',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                  if (response?.data?.status == 'accepted') {
                    this.$nextTick(() => {
                      if (response?.data?.roleId)
                        document
                          .getElementById('apply-btn_' + response?.data?.roleId)
                          ?.click();
                      else document.getElementById('apply-btn')?.click();
                    });
                  }
                }
              }
            ]
          );
        } else {
          this.openAppDialogInfo(
            'error',
            response.message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$store.dispatch(
          JOB_ORGANIZER_INITIAL_UPDATE_INVITATION_STATUS_STATE
        );
      },
      respondToInvitation(invitationId, status, roleId = null) {
        this.$store.dispatch(JOB_ORGANIZER_UPDATE_INVITATION_STATUS, {
          id: invitationId,
          data: { status },
          notificationId: null,
          roleId: status == 'accepted' ? roleId : null
        });
      },
      redirectBackward() {
        const pathName = this.$route.params?.redirect;
        if (pathName) {
          this.$router.push({
            name: ROUTE_NAME[pathName],
            params: {
              type: pathName == 'HOME' ? 'jobs' : '',
              id: this.id
            }
          });
        } else this.$router.go(-1);
      },
      checkBeforeApply(value = null) {
        const isUnionRequired =
          this.jobObj?.union_no_required == 1 && this.form.unionNo.value === '';

        if (isUnionRequired) {
          this.form.unionNo.store = value;
          this.$openModal('unionNoModal');
        } else {
          this.onApplyBtnClick(value);
        }
      },
      async submitUnionNumber() {
        const formValidate = this.$refs['unionForm'].validate();
        if (formValidate) {
          const { store: storedValue, value } = this.form.unionNo;
          this.onApplyBtnClick(storedValue, { union_no: value });
        }
      },
      onApplyBtnClick(value = null, query = {}) {
        const { application_fees, application_fees_is_paid } = this.jobObj;

        if (application_fees > 0 && application_fees_is_paid == false) {
          this.$openModal(
            'messageModal',
            `A participation fee of SGD ${application_fees} is required.`,
            {
              title: 'Ok',
              action: () => {
                let successUrl = `job-details/${this.jobObj.job_id}`;
                if (this.form.unionNo.value !== '') {
                  successUrl =
                    successUrl + `?union_no=${this.form.unionNo.value}`;
                }

                query.type = 'application_fee';
                query.job_id = this.jobObj.job_id;
                query.success_url = successUrl;

                this.$router.push({
                  name: ROUTE_NAME.PAYMENT_GATEWAY,
                  query: query
                });
                this.$closeModal();
              }
            },
            { show: true, customText: '' }
          );

          return;
        }

        if (this.me.role == 'agency') {
          this.getTalentList(value);
        } else {
          this.showModal(value);
        }
      }
    },
    async mounted() {
      await this.loadData();
      if (['talent', 'Talent'].includes(this.me.role)) {
        await this.getLinkedAgency();
      }
    }
  };
</script>

<style lang="scss" scoped>
  .jobTitle {
    font-size: 1.8rem;
    font-weight: bold;
  }

  .verification-status {
    position: relative;
  }

  .verification-status > .icon {
    position: absolute;
    right: -8px;
    bottom: -8px;
  }
  .showJob {
    margin-top: 225px !important;

    .jobImage {
      // position: absolute;
      width: auto;
      max-height: 400px;
      margin-top: -240px;
    }
  }

  .lg-font {
    font-size: 1.4rem;
  }
  .btn1.outline {
    border: none !important;
    pointer-events: none;
  }

  .mobileButtonContainer {
    position: relative;
  }
  .mobileTooltipPosition {
    position: absolute !important;
    top: 50%;
    right: 5%;
    margin-left: 10px;
    transform: translate(-5%, -50%);
    &.bottom {
      right: unset;
      transform: translate(10px, -50%);
    }
  }

  @media screen and (max-width: 960px) {
    .mobileTooltipPosition {
      right: unset;
      transform: translate(10px, -50%);
    }
  }
</style>
